exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /*This should be 0*/ /* layout contants from `layout-constants.js` */ .inline-message_inline-message_3EzKK {\n    color: black;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-size: large;\n    font-weight: bolder;\n    background: white;\n    border-radius: 12px;\n    padding: 5px;\n    padding-bottom: 24px;\n    width: 100%;\n    color: #f48a1f;\n    height: 25px;\n\n} .inline-message_success_1HE_O {\n    margin-right: 63px;\n} .inline-message_info_8Giql {\n    margin-right: 70px;\n} .inline-message_warn_3ryT7 {\n    margin-right: 45px;\n} .inline-message_spinner_H-tr3 {\n    display: none;\n    margin-right: 0.5rem;\n}", ""]);

// exports
exports.locals = {
	"inline-message": "inline-message_inline-message_3EzKK",
	"inlineMessage": "inline-message_inline-message_3EzKK",
	"success": "inline-message_success_1HE_O",
	"info": "inline-message_info_8Giql",
	"warn": "inline-message_warn_3ryT7",
	"spinner": "inline-message_spinner_H-tr3"
};