exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".save-status_save-now_1Blzi {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    cursor: pointer;\n    margin-right: 45px;\n    font-size: large;\n    font-weight: bolder;\n    background: white;\n    border-radius: 12px;\n    padding: 5px;\n    padding-bottom: 24px;\n    width: 100%;\n    color: #f48a1f;\n    height: 25px;\n}", ""]);

// exports
exports.locals = {
	"save-now": "save-status_save-now_1Blzi",
	"saveNow": "save-status_save-now_1Blzi"
};